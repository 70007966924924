/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import download from 'downloadjs';
import { batch } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
  FETCH_SELECTIONS_START,
  FETCH_SELECTIONS_FULFILLED,
  FETCH_SELECTIONS_REJECTED,
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
  FETCH_MAXREPORT_START,
  FETCH_MAXREPORT_FULFILLED,
  FETCH_MAXREPORT_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_START,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_START,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED,
  SET_ACTIVE_REDUX_MODAL_FOR_QUESTIONNAIRE_ATHLETE_ZONE,
  SET_ATHLETES_IN_CLICKED_ZONE,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_START,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_FULFILLED,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_REJECTED,
  FETCH_QUESTIONS_FOR_ACCOUNT_START,
  FETCH_QUESTIONS_FOR_ACCOUNT_FULFILLED,
  FETCH_QUESTIONS_FOR_ACCOUNT_REJECTED,
  SET_CURRENT_QUESTION_IN_QUESTIONNAIRE_PICKER,
  FETCH_MAXREPORT_CSV_START,
  FETCH_MAXREPORT_CSV_FULFILLED,
  SEARCH_SELECTION,
  SET_ACTIVE_REPORT,
  SET_MODAL_OPEN,
  SET_SIDEBAR_WIDTH,
  SET_LAYOUT_HEIGHT,
  SAVE_SIDEBAR_FILTER,
  CLEAR_REPORT,
  FETCH_ACTIVITY_REPORT_START,
  FETCH_ACTIVITY_REPORT_FULFILLED,
  FETCH_ACTIVITY_REPORT_REJECTED,
  SET_LATEST_ACTIVITY_FILTER,
  SET_SCHEDULED_WORKOUT_FILTER,
  DOWNLOAD_ACTIVITY_REPORT_START,
  DOWNLOAD_ACTIVITY_REPORT_FULFILLED,
  DOWNLOAD_ACTIVITY_REPORT_REJECTED,
  FETCH_RAW_DATA_REPORT_START,
  FETCH_RAW_DATA_REPORT_FULFILLED,
  FETCH_RAW_DATA_REPORT_REJECTED,
  SET_MOBILE_SIDEBAR_ACTIVE,
  FETCH_EVALUATIONS_START,
  FETCH_EVALUATIONS_FULFILLED,
  FETCH_EVALUATIONS_REJECTED,
  FETCH_EVALUATION_REPORT_GA_START,
  FETCH_EVALUATION_REPORT_GA_FULFILLED,
  FETCH_EVALUATION_REPORT_GA_REJECTED,
  FETCH_EVALUATION_REPORT_OVERVIEW_START,
  FETCH_EVALUATION_REPORT_OVERVIEW_FULFILLED,
  FETCH_EVALUATION_REPORT_OVERVIEW_REJECTED,
  SET_SHOW_ALL_FOR_DOWNLOAD,
  RESET_EVALUATION_REPORT_GA_ERROR,
  SET_IS_REPORT_OPEN,
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_ATHLETES_START,
  FETCH_ATHLETES_REJECTED,
  FETCH_ATHLETES_FULFILLED,
  FETCH_OPT_OUT_REPORT_START,
  FETCH_OPT_OUT_REPORT_FULFILLED,
  FETCH_OPT_OUT_REPORT_REJECTED,
  FETCH_RESULTS_REPORT_START,
  FETCH_RESULTS_REPORT_FULFILLED,
  FETCH_RESULTS_REPORT_REJECTED,
  FETCH_OPT_OUT_REPORT_CSV_START,
  FETCH_OPT_OUT_REPORT_CSV_FULFILLED,
  FETCH_OPT_OUT_REPORT_CSV_REJECTED,
  FETCH_COMPARISON_REPORT_START,
  FETCH_COMPARISON_REPORT_FULFILLED,
  FETCH_COMPARISON_REPORT_REJECTED,
  SET_COMPARISON_REPORT_ERROR,
  RESET_COMPARISON_REPORT_ERROR,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';

authProvider();

export const fetchSelections = (accountCode, selectionType) => (dispatch) => {
  const usersQuery = 'users?userType=0&groupFilter=reporting';
  const groupsQuery = 'groups?reporting=1';
  const calendarsQuery = 'calendars?archived=0';
  const isGroupsOrCalendars = selectionType === '2' ? groupsQuery : calendarsQuery;
  const activeQuery = selectionType === '1' ? usersQuery : isGroupsOrCalendars;

  dispatch({ type: FETCH_SELECTIONS_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/${activeQuery}`)
    .then((response) => {
      let filteredData = [];
      // Flatten the childCalendars into the main filterSelections array
      // so that subcalendars appear in dropdown when filtering by calendar
      if (selectionType === '3') {
        const flattenedData = response.data.reduce((acc, curr) => {
          // Add the current filter to the accumulator
          acc.push(curr);
          // Add all child calendars of the current filter to the accumulator
          acc.push(...curr.childCalendars);
          return acc;
        }, []);

        filteredData = flattenedData;
      } else {
        filteredData = response.data;
      }
      setTimeout(() => {
        dispatch({
          type: FETCH_SELECTIONS_FULFILLED,
          payload: filteredData,
        });
      }, 800);
    })
    .catch((err) => {
      dispatch({
        type: FETCH_SELECTIONS_REJECTED,
      });
    });
};

export const fetchExercises = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_EXERCISES_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/exercises?tracked=1`)
    .then((response) => {
      dispatch({
        type: FETCH_EXERCISES_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_EXERCISES_REJECTED,
        payload: err,
      });
    });
};

export const fetchQuestionnairesForAccount = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_QUESTIONNAIRES_FOR_ACCOUNT_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/questionnaires`)
    .then((response) => {
      dispatch({
        type: FETCH_QUESTIONNAIRES_FOR_ACCOUNT_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_QUESTIONNAIRES_FOR_ACCOUNT_REJECTED,
        payload: err,
      });
    });
};

export const fetchQuestionsForAccount = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_QUESTIONS_FOR_ACCOUNT_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/questions`)
    .then((response) => {
      dispatch({
        type: FETCH_QUESTIONS_FOR_ACCOUNT_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_QUESTIONS_FOR_ACCOUNT_REJECTED,
        payload: err,
      });
    });
};

export const fetchGroups = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_GROUPS_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/groups`)
    .then((response) => {
      dispatch({
        type: FETCH_GROUPS_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_GROUPS_REJECTED,
        payload: err,
      });
    });
};

export const fetchAthletes = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_ATHLETES_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/users?userType=0`)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_ATHLETES_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_ATHLETES_REJECTED,
      });
    });
};

export const fetchQuestionnaireReportSingleDay = (
  accountCode,
  filterValues,
  values,
  actionType,
) => (dispatch, getState) => {
  const { filterType, filterIds } = filterValues;

  const {
    startDate,
    currentDate,
    selectionType,
    questionnaire,
    arrayOfQuestions,
    showRangeAverages,
    showTotal,
    singleDaySelect,
    selectedDate,
  } = values;

  const questionnaireId = questionnaire?.id;
  const questionnaireQuery = questionnaireId
    ? `&questionnaire=${questionnaireId}`
    : '';

  const arrayOfQuestionIds = arrayOfQuestions.map((question) => question.id);
  const questionsQuery = questionnaireId
    ? ''
    : `&questions=${arrayOfQuestionIds}`;

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/questionnaire/single';
  const filterQuery = `?filterType=${filterType.value}`;
  const filterIdsQuery = `${filterIds
    .map((filter) => `&filterIds=${filter.id}`)
    .join('')}`;
  const dateQuery = `&date=${
    singleDaySelect ? selectedDate.queryFormat : currentDate.queryFormat
  }`;
  const averageQuery = `${showRangeAverages ? '&showAverages=true' : ''}`;
  const totalQuery = `${showTotal ? '&showTotal=true' : ''}`;

  const dateAssigned = values.dateAssigned === 2
    ? '&useDateAssigned=true'
    : '&useDateAssigned=false';

  dispatch({ type: FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_START });
  return axiosAuthed
    .get(
      `${accountString}${reportQuery}${filterQuery}${filterIdsQuery}${dateQuery}${questionnaireQuery}${questionsQuery}${averageQuery}${totalQuery}${dateAssigned}`,
    )
    .then((response) => {
      const apiResponseData = response.data;
      const dataRows = apiResponseData.filter(
        (obj) => obj.name !== 'Team Average',
      );

      /** array to loop through for the footer accessors to display team averages info */
      const teamAverageTotals = apiResponseData.filter(
        (obj) => obj.name === 'Team Average',
      )[0];

      const arrayOfAllQuestions = [];
      const getArrayOfAllQuestions = () => dataRows.map((athlete) => arrayOfAllQuestions.push(...athlete.questions));
      getArrayOfAllQuestions();

      const groupByCategory = arrayOfAllQuestions.reduce((group, question) => {
        const { id } = question;
        // eslint-disable-next-line no-param-reassign
        group[id] = group[id] ?? [];
        group[id].push(question);
        return group;
      }, {});

      const arrayOfGroupArrays = Object.values(groupByCategory);

      const donutChartData = arrayOfGroupArrays.map((groupArray) => ({
        title: groupArray[0].title,
        id: groupArray[0].id,
        groupQuestionResponsesFromEachAthlete: groupArray,
        red: groupArray.filter(
          (question) => question.response.backgroundColor === '#FF3737',
        ),
        yellow: groupArray.filter(
          (question) => question.response.backgroundColor === '#FFCB47',
        ),
        green: groupArray.filter(
          (question) => question.response.backgroundColor === '#0DCC8A',
        ),
        noColor: groupArray.filter(
          (question) => question.response.backgroundColor !== '#0DCC8A'
            || '#FFCB47'
            || '#FF3737',
        ),
      }));

      const totalScoresDonutChartDataRed = dataRows.filter(
        (athleteObj) => athleteObj.totalScores.value.backgroundColor === '#FF3737',
      );
      const totalScoresDonutChartDataGreen = dataRows.filter(
        (athleteObj) => athleteObj.totalScores.value.backgroundColor === '#0DCC8A',
      );
      const totalScoresDonutChartDataYellow = dataRows.filter(
        (athleteObj) => athleteObj.totalScores.value.backgroundColor === '#FFCB47',
      );

      setTimeout(() => {
        batch(() => {
          dispatch({
            type: FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED,
            payload: {
              data: apiResponseData,
              dataRows,
              teamAverageTotals,
              values,
              arrayOfAllQuestions,
              donutChartData,
              totalScoresDonutChartDataGreen,
              totalScoresDonutChartDataRed,
              totalScoresDonutChartDataYellow,
            },
          });
          dispatch({
            type: SET_MODAL_OPEN,
            payload: false,
          });
        });
      }, 800);
    })
    .catch((err) => {
      dispatch({
        type: FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED,
        payload: err,
      });
    });
};

export const fetchQuestionnaireReportMultiDay = (
  accountCode,
  filterValues,
  values,
  actionType,
) => (dispatch, getState) => {
  const { filterType, filterIds } = filterValues;

  const {
    startDate,
    endDate,
    selectionType,
    questionnaire,
    arrayOfQuestions,
    showRangeAverages,
    showTotal,
  } = values;

  const questionnaireId = questionnaire?.id;
  const questionnaireQuery = questionnaireId
    ? `&questionnaire=${questionnaireId}`
    : '';

  const arrayOfQuestionIds = arrayOfQuestions.map((question) => question.id);
  const questionsQuery = questionnaireId
    ? ''
    : `&questions=${arrayOfQuestionIds}`;

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/questionnaire/multiple';
  const filterQuery = `?filterType=${filterType.value}`;
  const filterIdsQuery = `${filterIds
    .map((filter) => `&filterIds=${filter.id}`)
    .join('')}`;
  const dateQuery = `&startDate=${startDate.queryFormat}&endDate=${endDate.queryFormat}`;
  const averageQuery = `${showRangeAverages ? '&showAverages=true' : ''}`;
  const totalQuery = `${showTotal ? '&showTotal=true' : ''}`;

  const dateAssigned = values.dateAssigned === 2
    ? '&useDateAssigned=true'
    : '&useDateAssigned=false';

  dispatch({ type: FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_START });
  return axiosAuthed
    .get(
      `${accountString}${reportQuery}${filterQuery}${filterIdsQuery}${dateQuery}${questionnaireQuery}${questionsQuery}${averageQuery}${totalQuery}${dateAssigned}`,
    )
    .then((apiResponse) => {
      const apiResponseData = apiResponse.data;

      const formatData = () => {
        /** to get total score for each athlete object we need to map through
         * the total scores array and return score date object that matches the date of
         * the question at index [i];
         */
        const totalScoreArraysForEachAthlete = apiResponseData.map(
          (athleteObject, i) => athleteObject.totals.scores,
        );

        const responsesArraysForEachAthlete = apiResponseData.map(
          (athleteObject, i) => athleteObject.responses,
        );
        const averagesArraysForEachAthlete = apiResponseData.map(
          (athleteObject) => athleteObject.averages,
        );

        const getResponseArray = (athleteObject, athleteIndex) => {
          const getNewQuestionsArray = (response) => {
            const questionsArrayForResponse = response.questions;
            const newQuestionsArrayForResponse = questionsArrayForResponse.map(
              (questionsObject, Qindex) => ({
                id: questionsObject.id,
                title: questionsObject.title,
                value: questionsObject.response.value,
                backgroundColor: questionsObject.response.backgroundColor,
                textColor: questionsObject.response.textColor,
                average: averagesArraysForEachAthlete[athleteIndex].filter(
                  (averagesObj, averagesObjectIndex) => averagesObjectIndex === Qindex,
                )[0].response.value,
                averageTextColor: averagesArraysForEachAthlete[
                  athleteIndex
                ].filter(
                  (averagesObj, averagesObjectIndex) => averagesObjectIndex === Qindex,
                )[0].response.textColor,
              }),
            );

            return newQuestionsArrayForResponse;
          };

          const newResponseArray = responsesArraysForEachAthlete[
            athleteIndex
          ].map((response, resonseIndex) => ({
            date: response.date,
            //* * map through questions, averages indexes should map with question indexes */
            questions: getNewQuestionsArray(response),
            totalScore: totalScoreArraysForEachAthlete[athleteIndex].filter(
              (scoreObject) => scoreObject.date === response.date,
            )[0].value,
            totalScoreBackgroundColor: totalScoreArraysForEachAthlete[
              athleteIndex
            ].filter((scoreObject) => scoreObject.date === response.date)[0]
              .backgroundColor,
            totalScoreTextColor: totalScoreArraysForEachAthlete[
              athleteIndex
            ].filter((scoreObject) => scoreObject.date === response.date)[0]
              .textColor,
            totalScoreAverage: athleteObject.totals.average.value,
            totalScoreAverageTextColor: athleteObject.totals.average.textColor,
          }));

          return newResponseArray;
        };

        const formattedData = apiResponseData.map(
          (athleteObject, athleteIndex) => ({
            name: athleteObject.name,
            id: athleteObject.id,
            responses: getResponseArray(athleteObject, athleteIndex),
          }),
        );
        return formattedData;
      };

      const formattedDataToPassToTable = formatData();

      setTimeout(() => {
        batch(() => {
          dispatch({
            type: FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED,
            payload: {
              data: apiResponseData,
              dataRows: formattedDataToPassToTable,
              values,
            },
          });
          dispatch({
            type: SET_MODAL_OPEN,
            payload: false,
          });
        });
      }, 800);
    })
    .catch((err) => {
      dispatch({
        type: FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED,
        payload: err,
      });
    });
};

export const fetchMaxReport = (
  filterValues,
  values,
  accountCode,
  actionType,
) => (dispatch, getState) => {
  const { filterType, filterIds } = filterValues;

  const {
    average,
    bodyWeight,
    exerciseIds,
    total,
    includeGeneratedMaxes,
    includeManualMaxes,
    rangeType,
    rangeDates,
    relativeStrength,
  } = values;

  // const currentPage = actionType !== 'loadMore' ? 1 : getState().leaderboard.data.leaderboards[id].page;
  // const page = actionType !== 'loadMore' ? 1 : currentPage + 1;

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/max';
  const filterQuery = `?filterType=${filterType.value}`;
  const filterIdsQuery = `${filterIds
    .map((filter) => `&filterIds[]=${filter.id}`)
    .join('')}`;
  const exerciseIdsQuery = `${exerciseIds
    .map((exercise) => `&exerciseIds[]=${exercise.id}`)
    .join('')}`;

  const averageQuery = `${average ? '&average=true' : ''}`;
  const bodyWeightQuery = `${bodyWeight ? '&bodyWeight=true' : ''}`;
  const relativeStrengthQuery = `${
    relativeStrength ? '&relativeStrength=true' : ''
  }`;
  const totalQuery = `${total ? '&total=true' : ''}`;
  const generatedMaxesQuery = `${
    includeGeneratedMaxes ? '&includeGeneratedMaxes=true' : ''
  }`;
  const manualMaxesQuery = `${
    includeManualMaxes ? '&includeManualMaxes=true' : ''
  }`;

  const rangeQuery = `${rangeType ? `&rangeType=${rangeType}` : ''}`;
  const rangeMinDate = moment(rangeDates.startDate).format('YYYY-MM-DD');
  const rangeMaxDate = moment(rangeDates.endDate).format('YYYY-MM-DD');
  const rangeDatesQuery = `${
    rangeDates && rangeType === 4
      ? `&rangeMinDate=${rangeMinDate}&rangeMaxDate=${rangeMaxDate}`
      : ''
  }`;
  const downloadQuery = `${
    actionType === 'downloadMaxReport' ? '&format=csv' : ''
  }`;

  // Default ID parameter if undefined in Add Board,
  // otherwise use namespace used in Edit Board.
  // eslint-disable-next-line no-param-reassign
  actionType = actionType !== undefined ? actionType : '';

  if (actionType !== 'downloadMaxReport') {
    dispatch({ type: FETCH_MAXREPORT_START });
  }
  if (actionType === 'downloadMaxReport') {
    dispatch({ type: FETCH_MAXREPORT_CSV_START });
  }

  return axiosAuthed
    .get(
      `${accountString}${reportQuery}${filterQuery}${filterIdsQuery}${exerciseIdsQuery}${averageQuery}${bodyWeightQuery}${relativeStrengthQuery}${totalQuery}${generatedMaxesQuery}${manualMaxesQuery}${rangeQuery}${rangeDatesQuery}${downloadQuery}`,
    )
    .then((response) => {
      if (actionType !== 'downloadMaxReport') {
        setTimeout(() => {
          batch(() => {
            dispatch({
              type: FETCH_MAXREPORT_FULFILLED,
              payload: {
                data: response.data,
                values,
              },
            });
            dispatch({
              type: SET_MODAL_OPEN,
              payload: false,
            });
          });
        }, 800);
      }
      if (actionType === 'downloadMaxReport') {
        dispatch({
          type: FETCH_MAXREPORT_CSV_FULFILLED,
          payload: response.data,
        });
        download(response.data, 'Teambuildr Max Report.csv', 'text/csv');
      }
    })
    .catch((err) => {
      dispatch({
        type: FETCH_MAXREPORT_REJECTED,
        payload: err,
      });
    });
};

export const searchSelection = (value) => ({
  type: SEARCH_SELECTION,
  payload: value,
});

export const setActiveReport = (value) => ({
  type: SET_ACTIVE_REPORT,
  payload: value,
});

export const setCurrentQuestionInQuestionnairePicker = (value) => ({
  type: SET_CURRENT_QUESTION_IN_QUESTIONNAIRE_PICKER,
  payload: value,
});

export const setModalOpen = (isModalOpen, activeModal) => ({
  type: SET_MODAL_OPEN,
  payload: {
    isModalOpen,
    activeModal,
  },
});

export const setMobileSidebarActive = () => ({
  type: SET_MOBILE_SIDEBAR_ACTIVE,
});

export const setSidebarWidth = (value) => ({
  type: SET_SIDEBAR_WIDTH,
  payload: value,
});

export const setLayoutHeight = (value) => ({
  type: SET_LAYOUT_HEIGHT,
  payload: value,
});

export const saveSidebarFilter = (values) => ({
  type: SAVE_SIDEBAR_FILTER,
  payload: values,
});

export const clearReport = () => ({
  type: CLEAR_REPORT,
});

export const resetEvaluationReportGAError = () => ({
  type: RESET_EVALUATION_REPORT_GA_ERROR,
});

export const fetchActivityReport = (filterValues, accountCode) => (
  dispatch,
  getState,
) => {
  const { filterType, filterIds } = filterValues;

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/activity';
  const filterQuery = `?filterType=${filterType.value}`;
  const filterIdsQuery = `${filterIds
    .map((filter) => `&filterIds[]=${filter.id}`)
    .join('')}`;

  dispatch({ type: FETCH_ACTIVITY_REPORT_START });

  return axiosAuthed
    .get(`${accountString}${reportQuery}${filterQuery}${filterIdsQuery}`)
    .then((response) => {
      dispatch({
        type: FETCH_ACTIVITY_REPORT_FULFILLED,
        payload: {
          data: response.data,
          filterValues,
        },
      });
    })
    .catch((err) => {
      if (err.response.status === 403) {
        alert('Blackout date');
      } else if (err.response.status === 400) {
        alert('No data to report');
      }
      dispatch({
        type: FETCH_ACTIVITY_REPORT_REJECTED,
        payload: err,
      });
    });
};

export const downloadActivityReport = (filterValues, accountCode) => (
  dispatch,
  getState,
) => {
  const { filterType, filterIds } = filterValues;

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/activity';
  const filterQuery = `?filterType=${filterType.value}`;
  const filterIdsQuery = `${filterIds
    .map((filter) => `&filterIds[]=${filter.id}`)
    .join('')}`;

  dispatch({ type: DOWNLOAD_ACTIVITY_REPORT_START });

  return axiosAuthed
    .get(
      `${accountString}${reportQuery}${filterQuery}${filterIdsQuery}&format=csv`,
    )
    .then((response) => {
      dispatch({
        type: DOWNLOAD_ACTIVITY_REPORT_FULFILLED,
      });
      download(response.data, 'Teambuildr Activity Report.csv', 'text/csv');
    })
    .catch((err) => {
      dispatch({
        type: DOWNLOAD_ACTIVITY_REPORT_REJECTED,
        payload: err,
      });
    });
};

export const fetchOptOutReport = (filterValues, accountCode, values) => (
  dispatch,
) => {
  dispatch({ type: FETCH_OPT_OUT_REPORT_START });

  const { filterType, filterIds } = filterValues;

  const rangeQuery = `${
    values.rangeType ? `&rangeType=${values.rangeType}` : '&rangeType=1'
  }`;

  let rangeMaxDate;
  if (values.rangeType === 4) {
    rangeMaxDate = moment(values.endDate.date).format('YYYY-MM-DD');
  } else if (values.rangeType === 3) {
    rangeMaxDate = moment().format('YYYY-MM-DD');
  } else if (values.rangeType === 2) {
    rangeMaxDate = moment().format('YYYY-MM-DD');
  }

  let rangeMinDate;
  if (values.rangeType === 4) {
    rangeMinDate = moment(values.startDate.date).format('YYYY-MM-DD');
  } else if (values.rangeType === 3) {
    rangeMinDate = moment().format('YYYY-MM-DD');
  } else if (values.rangeType === 2) {
    rangeMinDate = moment(rangeMaxDate)
      .subtract(30, 'days')
      .format('YYYY-MM-DD');
  }

  const rangeDatesQuery = `${
    values && values.rangeType !== 1
      ? `&rangeMinDate=${rangeMinDate}&rangeMaxDate=${rangeMaxDate}`
      : ''
  }`;

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/opt-out';
  const filterQuery = `?filterType=${filterType.value}`;
  const filterIdsQuery = `${filterIds
    .map((filter) => `&filterIds[]=${filter.id}`)
    .join('')}`;

  return axiosAuthed
    .get(
      `${accountString}${reportQuery}${filterQuery}${filterIdsQuery}${rangeQuery}${rangeDatesQuery}`,
    )
    .then((response) => {
      dispatch({
        type: FETCH_OPT_OUT_REPORT_FULFILLED,
        payload: {
          data: response.data,
          values,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_OPT_OUT_REPORT_REJECTED,
        payload: err,
      });
      toast.error('There was an error fetching the report');
    });
};

export const downloadOptOutNotesReport = (
  filterValues,
  accountCode,
  values,
) => (dispatch) => {
  dispatch({ type: FETCH_OPT_OUT_REPORT_CSV_START });

  const { filterType, filterIds } = filterValues;

  const rangeQuery = `${
    values.rangeType ? `&rangeType=${values.rangeType}` : '&rangeType=1'
  }`;

  const rangeMinDate = moment(values.startDate.date).format('YYYY-MM-DD');
  const rangeMaxDate = moment(values.endDate.date).format('YYYY-MM-DD');

  const rangeDatesQuery = `${
    values && values.rangeType === 4
      ? `&rangeMinDate=${rangeMinDate}&rangeMaxDate=${rangeMaxDate}`
      : ''
  }`;

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/opt-out';
  const filterQuery = `?filterType=${filterType.value}`;
  const filterIdsQuery = `${filterIds
    .map((filter) => `&filterIds[]=${filter.id}`)
    .join('')}`;

  return axiosAuthed
    .get(
      `${accountString}${reportQuery}${filterQuery}${filterIdsQuery}${rangeQuery}${rangeDatesQuery}&format=csv`,
    )
    .then((response) => {
      dispatch({
        type: FETCH_OPT_OUT_REPORT_CSV_FULFILLED,
        payload: {
          data: response.data,
          values,
        },
      });
      download(response.data, 'Teambuildr Raw Data Report.csv', 'text/csv');
    })
    .catch((err) => {
      dispatch({
        type: FETCH_OPT_OUT_REPORT_CSV_REJECTED,
        payload: err,
      });
    });
};

export const fetchWorkoutResults = (filterValues, accountCode, values) => (
  dispatch,
) => {
  dispatch({ type: FETCH_RESULTS_REPORT_START });

  const { filterType, filterIds } = filterValues;

  const rangeQuery = `${
    values.rangeType ? `&rangeType=${values.rangeType}` : '&rangeType=2'
  }`;

  const dateAssigned = values.dateAssigned === 2 ? '&dateAssigned=true' : '&dateAssigned=false';

  let rangeMaxDate;
  if (values.rangeType === 4) {
    rangeMaxDate = moment(values.endDate.date).format('YYYY-MM-DD');
  } else if (values.rangeType === 3) {
    rangeMaxDate = moment().format('YYYY-MM-DD');
  } else if (values.rangeType === 2) {
    rangeMaxDate = moment().format('YYYY-MM-DD');
  }

  let rangeMinDate;
  if (values.rangeType === 4) {
    rangeMinDate = moment(values.startDate.date).format('YYYY-MM-DD');
  } else if (values.rangeType === 3) {
    rangeMinDate = moment().format('YYYY-MM-DD');
  } else if (values.rangeType === 2) {
    rangeMinDate = moment(rangeMaxDate)
      .subtract(30, 'days')
      .format('YYYY-MM-DD');
  }

  const rangeDatesQuery = `&rangeMinDate=${rangeMinDate}&rangeMaxDate=${rangeMaxDate}`;

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/workout-results';
  const filterQuery = `?filterType=${filterType.value}`;
  const filterIdsQuery = `${filterIds
    .map((filter) => `&filterIds[]=${filter.id}`)
    .join('')}`;

  return axiosAuthed
    .get(
      `${accountString}${reportQuery}${filterQuery}${filterIdsQuery}${rangeQuery}${rangeDatesQuery}${dateAssigned}`,
    )
    .then((response) => {
      dispatch({
        type: FETCH_RESULTS_REPORT_FULFILLED,
        payload: {
          data: response.data,
          values,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_RESULTS_REPORT_REJECTED,
        payload: err,
      });
      toast.error('There was an error fetching the report');
    });
};

export const downloadRawDataReport = (filterValues, values, accountCode) => (
  dispatch,
  getState,
) => {
  const { filterType, filterIds } = filterValues;

  const {
    showAdvancedWorkoutData,
    showExternalIds,
    showTags,
    rangeDates,
    dataSetType,
    includeGeneratedPRs,
    maxExerciseType,
  } = values;

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/raw_data';
  const filterQuery = `?filterType=${filterType.value}`;
  const filterIdsQuery = `${filterIds
    .map((filter) => `&filterIds[]=${filter.id}`)
    .join('')}`;
  const optionsQuery = `&showAdvancedData=${
    showAdvancedWorkoutData ? 1 : 0
  }&showTags=${showTags ? 1 : 0}&showExternalIds=${showExternalIds ? 1 : 0}`;
  const dateQuery = `&startDate=${moment(rangeDates.startDate).format(
    'YYYY-MM-DD',
  )}&endDate=${moment(rangeDates.endDate).format('YYYY-MM-DD')}`;
  const dataSetTypeQuery = `&dataSetType=${dataSetType}`;
  const maxReportQueries = dataSetType === 3
    ? `&includeGeneratedMaxes=${
      includeGeneratedPRs ? 1 : 0
    }&maxReportExerciseType=${maxExerciseType}`
    : '';

  dispatch({ type: FETCH_RAW_DATA_REPORT_START });

  return axiosAuthed
    .get(
      `${accountString}${reportQuery}${filterQuery}${filterIdsQuery}${optionsQuery}${dateQuery}${dataSetTypeQuery}${maxReportQueries}&format=csv`,
    )
    .then((response) => {
      dispatch({
        type: FETCH_RAW_DATA_REPORT_FULFILLED,
      });
      if (response.data) {
        download(response.data, 'Teambuildr Raw Data Report.csv', 'text/csv');
      } else {
        // Otherwise no content
        alert('No data');
      }
    })
    .catch((err) => {
      if (err.response.status === 403) {
        alert('Blackout date');
      }
      dispatch({
        type: FETCH_RAW_DATA_REPORT_REJECTED,
        payload: err,
      });
    });
};

export const setLatestActivityFilter = (value) => ({
  type: SET_LATEST_ACTIVITY_FILTER,
  payload: value,
});

export const setScheduledWorkoutFilter = (value) => ({
  type: SET_SCHEDULED_WORKOUT_FILTER,
  payload: value,
});

export const setIsReportOpen = (bool, type) => ({
  type: SET_IS_REPORT_OPEN,
  payload: {
    bool,
    type,
  },
});

export const setActiveReduxModalForQuestionnaireAthleteZone = (
  activeModal,
) => ({
  type: SET_ACTIVE_REDUX_MODAL_FOR_QUESTIONNAIRE_ATHLETE_ZONE,
  payload: activeModal,
});
export const setAthletesInClickedZone = (athletes) => ({
  type: SET_ATHLETES_IN_CLICKED_ZONE,
  payload: athletes,
});

export const fetchEvaluations = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_EVALUATIONS_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/evaluations`)
    .then((response) => {
      dispatch({
        type: FETCH_EVALUATIONS_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_EVALUATIONS_REJECTED,
        payload: err,
      });
    });
};

export const fetchEvaluationReportGA = (accountCode, values, filterValues) => (
  dispatch,
) => {
  const { filterType, filterIds } = filterValues;

  const {
    evaluation,
    firstStartDate,
    firstEndDate,
    secondStartDate,
    secondEndDate,
  } = values;

  const dateQuery = secondStartDate.date
    ? `firstStartDate=${firstStartDate.queryFormat}&firstEndDate=${firstEndDate.queryFormat}&secondStartDate=${secondStartDate.queryFormat}&secondEndDate=${secondEndDate.queryFormat}`
    : `firstStartDate=${firstStartDate.queryFormat}&firstEndDate=${firstEndDate.queryFormat}`;
  const filterIdsQuery = `${filterIds
    .map((filter) => `${filter.id}`)
    .join(',')}`;

  dispatch({ type: FETCH_EVALUATION_REPORT_GA_START });
  axiosAuthed
    .get(
      `/accounts/${accountCode}/reports/evaluations/${evaluation.id}/groupAnalysis?${dateQuery}&filterType=${filterType.value}&filterIds=${filterIdsQuery}`,
    )
    .then((response) => {
      setTimeout(() => {
        batch(() => {
          dispatch({
            payload: {
              data: response.data,
              values,
            },
            type: FETCH_EVALUATION_REPORT_GA_FULFILLED,
          });
          dispatch({
            type: SET_MODAL_OPEN,
            payload: {
              isModalOpen: false,
            },
          });
        });
      }, 800);
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: FETCH_EVALUATION_REPORT_GA_REJECTED,
      });
    });
};

export const fetchEvaluationReportOverview = (
  accountCode,
  values,
  filterValues,
) => (dispatch) => {
  const { filterType, filterIds } = filterValues;

  const {
    evaluation,
    firstStartDate,
    firstEndDate,
    secondStartDate,
    secondEndDate,
  } = values;

  const dateQuery = secondStartDate.date
    ? `firstStartDate=${firstStartDate.queryFormat}&firstEndDate=${firstEndDate.queryFormat}&secondStartDate=${secondStartDate.queryFormat}&secondEndDate=${secondEndDate.queryFormat}`
    : `firstStartDate=${firstStartDate.queryFormat}&firstEndDate=${firstEndDate.queryFormat}`;
  const filterIdsQuery = `${filterIds
    .map((filter) => `${filter.id}`)
    .join(',')}`;

  dispatch({ type: FETCH_EVALUATION_REPORT_OVERVIEW_START });
  axiosAuthed
    .get(
      `/accounts/${accountCode}/reports/evaluations/${evaluation.id}/overview?${dateQuery}&filterType=${filterType.value}&filterIds=${filterIdsQuery}`,
    )
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_EVALUATION_REPORT_OVERVIEW_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: FETCH_EVALUATION_REPORT_OVERVIEW_REJECTED,
      });
    });
};

export const setShowAllForDownload = (bool) => ({
  type: SET_SHOW_ALL_FOR_DOWNLOAD,
  payload: bool,
});

export const fetchComparisonReport = (accountCode, values, filterValues) => (
  dispatch,
) => {
  const { filterType, filterIds } = filterValues;

  const {
    exerciseIds,
    includeGeneratedMaxes,
    includeManualMaxes,
    includeEmptyExercises,
    rangeType,
    startDate,
    endDate,
    dateFormat,
  } = values;

  let rangeMaxDate;
  if (values.rangeType === 4) {
    rangeMaxDate = endDate.queryFormat;
  } else if (values.rangeType === 3) {
    rangeMaxDate = moment().format('YYYY-MM-DD');
  } else if (values.rangeType === 2) {
    rangeMaxDate = moment().format('YYYY-MM-DD');
  }

  let rangeMinDate;
  if (values.rangeType === 4) {
    rangeMinDate = startDate.queryFormat;
  } else if (values.rangeType === 3) {
    rangeMinDate = moment().format('YYYY-MM-DD');
  } else if (values.rangeType === 2) {
    rangeMinDate = moment(rangeMaxDate)
      .subtract(30, 'days')
      .format('YYYY-MM-DD');
  }

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/comparison';
  const filterQuery = `?filterType=${filterType.value}`;
  const filterIdsList = filterIds.map((filter) => filter.id).join(',');
  const filterIdsQuery = `&filterIds=${filterIdsList}`;
  const exerciseIdsList = exerciseIds.filter((exercise) => exercise !== '').map((exercise) => `${exercise.id}`).join(',');
  const exerciseIdsQuery = `&exerciseIds=${exerciseIdsList}`;

  const generatedMaxesQuery = `&includeGeneratedMaxes=${!!includeGeneratedMaxes}`;
  const manualMaxesQuery = `&includeManualMaxes=${!!includeManualMaxes}`;
  const showEmptyCellsQuery = `&showEmptyCells=${!!includeEmptyExercises}`;

  const rangeQuery = `${rangeType ? `&rangeType=${rangeType}` : ''}`;
  const rangeDatesQuery = `${
    startDate && endDate && rangeType === 4
      ? `&rangeMinDate=${rangeMinDate}&rangeMaxDate=${rangeMaxDate}`
      : ''
  }`;
  const dateFormatQuery = `&dateFormat=${dateFormat}`;

  dispatch({ type: FETCH_COMPARISON_REPORT_START });
  axiosAuthed
    .get(
      `${accountString}${reportQuery}${filterQuery}${filterIdsQuery}${exerciseIdsQuery}${generatedMaxesQuery}${manualMaxesQuery}${showEmptyCellsQuery}${rangeQuery}${rangeDatesQuery}${dateFormatQuery}`,
    )
    .then((response) => {
      setTimeout(() => {
        batch(() => {
          dispatch({
            payload: {
              data: response.data,
              values,
            },
            type: FETCH_COMPARISON_REPORT_FULFILLED,
          });
          dispatch({
            type: SET_MODAL_OPEN,
            payload: {
              isModalOpen: false,
            },
          });
        });
      }, 800);
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: FETCH_COMPARISON_REPORT_REJECTED,
      });
    });
};

export const setComparisonReportError = (message) => ({
  type: SET_COMPARISON_REPORT_ERROR,
  payload: message,
});

export const resetComparisonReportError = () => ({
  type: RESET_COMPARISON_REPORT_ERROR,
});
