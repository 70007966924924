import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import IcomoonReact from 'icomoon-react';
import TableToExcel from '@linways/table-to-excel';

import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { setShowAllForDownload } from '../../ducks/reportingActions';

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const DownloadComparisonReport = ({
  closeModal,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const exportComparisonReportFile = () => {
    const comparisonReportTable = document.getElementById('comparisonTable');

    TableToExcel.convert(comparisonReportTable, {
      name: 'comparison-report.xlsx',
      sheet: {
        name: 'Sheet 1',
      },
    });
  };

  useEffect(() => {
    dispatch(setShowAllForDownload(true));
  }, []);

  const DownloadData = () => {
    exportComparisonReportFile();
    closeModal();
  };

  return (
    <>
      <NavigationWrapper>
        <Text
          className='close arrow-div'
          onClick={() => {
            closeModal();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <Text
        fontSize='18px'
        textAlign='center'
      >
        Would you like to download this comparison report as an excel file?
      </Text>
      <Button
        cta='Download Report'
        className='modal-button'
        type='button'
        onClick={DownloadData}
        customColor={theme.colors.green}
        fullWidth
        noBorder
        large
        square
        primary
        bottom
      />
    </>
  );
};

DownloadComparisonReport.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default DownloadComparisonReport;
