import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';

import { setSidebarWidth, setLayoutHeight } from '../ducks/reportingActions';
import ReportingSidebarFilter from './ReportingSidebarFilter';
import ActiveReportSidebar from './ActiveReportSidebar';
import { TransitionGlobal } from '../../../shared/GlobalStyles';
import Button from '../../../shared/components/Button/Button';

const SidebarContainer = styled('aside')`
  height: 100%;
  left: 0px;
  background: ${(props) => (props.activeReport === 'questionnaire' && props.isReportOpen ? '#f5f5f5' : props.theme.colors.background[0])};
  align-self: flex-start;
  transition: ${TransitionGlobal};
  width: ${(props) => (props.isReportOpen ? '100px' : '350px')};

  h1 {
    color: ${(props) => (props.theme.colors.text)};
    letter-spacing: 1.3px;
    margin-bottom: 15px;
    font-size: 22px;
  }

  @media screen and (max-width: 767px) {
    position: absolute;
    width: 100%;
    transform: ${(props) => (props.isMobileSidebarActive ? 'translate(0px, -0%)' : 'translate(0px, -110%)')};
    height: calc(100% - 49px);
    padding-bottom: 80px;
    z-index: 100000
  }

  @media screen and (min-width: 767px) and (max-width: 1024px) {
    width: ${(props) => (props.isReportOpen ? '100px' : '300px')};
  }
`;

const ReportingSidebar = () => {
  const [isMobileSidebarActive, setMobileSidebarActive] = useState(false);
  const isReportOpen = useSelector((state) => state.reporting.ui.isReportOpen);
  const activeModal = useSelector((state) => state.reporting.ui.activeModal);
  const sidebarFilter = useSelector((state) => state.reporting.data.sidebarFilter);
  const activeReport = useSelector((state) => state.reporting.ui.activeReport);

  const dispatch = useDispatch();

  const updateSidebarWidth = (width) => {
    dispatch(setSidebarWidth(width));
  };
  const updateLayoutHeight = (height) => {
    dispatch(setLayoutHeight(height));
  };

  return (
    <>
      <SidebarContainer
        activeModal={activeModal}
        isReportOpen={isReportOpen || activeModal === 'downloadMaxReport' || activeModal === 'downloadActivityReport' || activeModal === 'downloadQuestionnaireReport' || activeModal === 'downloadEvaluationReport' || activeModal === 'downloadOptOutNotesReport' || activeModal === 'downloadResultsReport' || activeReport === 'downloadComparisonReport'}
        isMobileSidebarActive={isMobileSidebarActive}
        activeReport={activeReport}
      >
        <ResizeObserver
          onResize={(rect) => {
            updateSidebarWidth(rect.width);
            updateLayoutHeight(rect.height);
          }}
        />
        {!isReportOpen && activeModal !== 'downloadMaxReport' && activeModal !== 'downloadActivityReport' && activeModal !== 'downloadQuestionnaireReport' && activeModal !== 'downloadEvaluationReport' && activeModal !== 'downloadOptOutNotesReport' && activeModal !== 'downloadResultsReport' && activeModal !== 'downloadComparisonReport' ? (
          <ReportingSidebarFilter />
        ) : (
          <ActiveReportSidebar
            edit={activeReport === 'max' || activeReport === 'questionnaire' || activeReport === 'workout_results' || activeReport === 'opt_out_notes' || activeReport === 'comparison'}
            download={activeReport === 'max' || activeReport === 'activity' || activeReport === 'questionnaire'}
          />
        )}
      </SidebarContainer>
      {!isReportOpen && (
        <Button
          complete={!!isMobileSidebarActive}
          className='filter-button'
          cta={`${isMobileSidebarActive ? (
            'Finish Selection'
          ) : (
            `${sidebarFilter.filterIds ? sidebarFilter.filterIds.length : 0} ${sidebarFilter.filterType && sidebarFilter.filterType.label.split(' ')[2]}s Selected`
          )}`}
          noBorder
          onClick={() => setMobileSidebarActive(!isMobileSidebarActive && true)}
          icon={isMobileSidebarActive ? 'checkmark' : ''}
        />
      )}
    </>
  );
};

export default ReportingSidebar;
