/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import { useSelector, useDispatch } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import { FieldArray, Form } from 'formik';
import Select from 'react-select';
import moment from 'moment';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { toast } from 'react-toastify';
import ReportDatePicker from '../BaseResultsReport/ReportDatePicker';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import FormHandler, {
  FormGroup,
} from '../../../../shared/components/FormHandler/FormHandler';
import {
  fetchExercises,
  fetchComparisonReport,
  setComparisonReportError,
  resetComparisonReportError,
} from '../../ducks/reportingActions';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import FormLabel from '../../../../shared/components/FormLabel/FormLabel';
import Toggle from '../../../../shared/components/Toggle/Toggle';
import Link from '../../../../shared/components/Link/Link';
import { reportInteractionTracker } from '../../../../shared/utils/amplitudeHelper';
import { fromPairs } from 'lodash';

const selectBoxOptions = [
  {
    value: 2,
    label: '30 Days',
  },
  {
    value: 3,
    label: 'Today',
  },
  {
    value: 4,
    label: 'Custom Range',
  },
];

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const ErrorBox = styled('div')`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #cc0404;
  background: #ffcdcd;
  color: #444;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 24px */
  margin-bottom: 20px;
`;

const FieldError = styled('div')`
  margin: 10px 0px;
  font-size: 14px;
  color: #ff6600;
`;

const FormSection = styled('div')`
  margin-bottom: 20px;
  .item-select {
    margin-bottom: ${(props) => (props.customDate ? '0px' : '0')};
  }
  .radioOptions {
    gap: 8px;
  }
  label {
    margin-bottom: 0px;
    height: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }
  span {
    padding-top: 0;
    padding-bottom: 0;
  }
  .date-picker-label {
    margin-top: 20px;
    margin-bottom: 12px;
  }
`;

const FormSectionHeader = styled('div')`
  font-family: "Nunito Sans";
  font-size: 12pt;
  color: #444444;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
`;

const ThirtyDayMessage = styled('div')`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--theme-info-default, #5f8bdc);
  background: var(--theme-info-subtle, #e4edff);
  color: var(--theme-fg-default, #444);
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Nunito Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 8px;
`;

const OptionsSection = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const OptionsColumn = styled('div')`
  width: 50%;
  @media screen and (max-width: 840px) {
    width: 100%;
  }
`;

const ReportOptionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;
  label {
    margin-bottom: 0px;
  }
`;

const ReportOptionsRow = styled('div')`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  gap: 8px;
  padding-right: 15px;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  p {
    font-size: 16px;
    font-weight: 400;
  }

  @media screen and (max-width: 580px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

const ToggleLabel = styled('div')`
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const ExerciseSelectColumn = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  label {
    margin-bottom: 0px;
  }
`;

const ExerciseSelectContainer = styled('div')`
  display: flex;
  width: 50%;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 15px 0px 0px;
  min-width: 50%;
  max-width: 50%;
  position: relative;

  .remove-link {
    position: absolute;
    right: 0;
    margin-right: 16px;
  }

  @media screen and (max-width: 580px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

const AddExerciseButtonContainer = styled('div')`
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 540px) {
    button {
      margin-left: 0px !important;
    }
  }
`;

const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;
  left: 0;
  margin-top: 40px;
`;

const ComparisonReportForm = ({ closeModal }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [activeSlide, setActiveSlide] = useState(0);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const { accountCode } = currentUser;
  const exercises = useSelector((state) => state.reporting.data.exercises);
  const comparisonReportForm = useSelector(
    (state) => state.reporting.data.comparisonReportForm,
  );
  const comparisonReportError = useSelector(
    (state) => state.reporting.data.comparisonReportError,
  );
  const isLoadingComparisonReport = useSelector(
    (state) => state.reporting.ui.isLoadingComparisonReport,
  );
  const sidebarFilter = useSelector(
    (state) => state.reporting.data.sidebarFilter,
  );

  useEffect(() => {
    dispatch(fetchExercises(accountCode));
    dispatch(resetComparisonReportError());
  }, []);

  const fetchReport = (values) => {
    if (!sidebarFilter.filterIds.length) {
      toast.error('You must select filters before continuing');
      // Amplitude tracker.
      reportInteractionTracker(
        'Report Error',
        'Comparison',
        'Reporting Filter has not been selected.',
      );
    } else if (!values.rangeType) {
      dispatch(setComparisonReportError('You must select a date type before continuing'));
      // Amplitude tracker.
      reportInteractionTracker(
        'Report Error',
        'Comparison',
        'Date type has not been selected',
      );
    } else if (
      values.rangeType === 4
      && (values.startDate.date === null || values.endDate.date === null)
    ) {
      dispatch(setComparisonReportError('You must specify start and end dates when selecting a custom date range'));
      // // Amplitude tracker.
      reportInteractionTracker(
        'Report Error',
        'Comparison',
        'Custom start and/or end dates not selected',
      );
    } else if (!values.dateFormat) {
      dispatch(setComparisonReportError('You must select a preferred date format'));
      // // Amplitude tracker.
      reportInteractionTracker(
        'Report Error',
        'Comparison',
        'Date format not selected',
      );
    } else if (!values.includeManualMaxes && !values.includeGeneratedMaxes) {
      dispatch(setComparisonReportError('Please include at least one type of max record from report options to run this report'));
      // // Amplitude tracker.
      reportInteractionTracker(
        'Report Error',
        'Comparison',
        'Manually entered maxes and workout generated maxes both excluded',
      );
    } else if (!values.exerciseIds.some((exercise) => exercise !== '')) {
      dispatch(setComparisonReportError('You must select at least one exercise'));
      // // Amplitude tracker.
      reportInteractionTracker(
        'Report Error',
        'Comparison',
        'Exercise(s) not selected',
      );
    } else {
      dispatch(fetchComparisonReport(accountCode, values, sidebarFilter));
      // Amplitude tracker.
      reportInteractionTracker(
        'Report Submitted',
        'Comparison',
        '',
        sidebarFilter.filterIds.length,
        sidebarFilter.filterType.indicator,
      );
    }
  };

  const customStyles = {
    option: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  const formAnimation = useSpring({
    opacity: isLoadingComparisonReport ? 0 : 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({
    opacity: isLoadingComparisonReport ? 1 : 0,
    zIndex: 0,
  });

  return (
    <>
      <NavigationWrapper>
        {activeSlide === 1 && (
          <Text
            className='back arrow-div'
            onClick={() => {
              setActiveSlide(0);
            }}
          >
            <IcomoonReact iconSet={iconSet} size={15} icon='left-arrow' />
          </Text>
        )}
        <Text
          className='close arrow-div'
          onClick={() => {
            closeModal();
          }}
        >
          <IcomoonReact iconSet={iconSet} size={13} icon='remove' />
        </Text>
      </NavigationWrapper>
      <FormHandler
        initialValues={
          Object.keys(comparisonReportForm).length !== 0
            ? comparisonReportForm
            : {
              startDate: {
                date: null,
                queryFormat: '',
              },
              endDate: {
                date: null,
                queryFormat: '',
              },
              includeManualMaxes: true,
              includeGeneratedMaxes: true,
              includeEmptyExercises: true,
              exerciseIds: [''],
              dateFormat: '',
            }
        }
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            Logger.debug('Form Values:', values);
            fetchReport(values);
          });
        }}
      >
        {(formProps) => (
          <Form style={{ width: '100%' }}>
            <animated.div style={formAnimation}>
              {comparisonReportError.message && (
                <ErrorBox>{comparisonReportError.message}</ErrorBox>
              )}
              {formProps.errors.exercise && formProps.touched.exercise ? (
                <FieldError className='text-center'>
                  {formProps.errors.exercise}
                </FieldError>
              ) : null}
              <>
                <>
                  <FormSection customDate={formProps.values.rangeType === 4}>
                    <FormSectionHeader>Select Date Type</FormSectionHeader>
                    <Select
                      className='item-select'
                      options={selectBoxOptions}
                      menuPortalTarget={document.body}
                      styles={customStyles}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={(selectedType) => {
                        if (comparisonReportError.message === 'You must select a date type before continuing') {
                          dispatch(resetComparisonReportError());
                        }
                        formProps.setFieldValue(
                          'rangeType',
                          selectedType.value,
                        );
                      }}
                      value={
                        formProps.values.rangeType
                          ? selectBoxOptions.find(
                            (option) => option.value === formProps.values.rangeType,
                          )
                          : null
                      }
                    />
                    {formProps.values.rangeType === 2 && (
                      <ThirtyDayMessage>
                        This will display data from the last 30 days, counting
                        back from today.
                      </ThirtyDayMessage>
                    )}
                    {formProps.values.rangeType === 4 ? (
                      <ReportDatePicker
                        formProps={formProps}
                        setStartDate={(date) => {
                          formProps.setFieldValue('startDate', {
                            date,
                            queryFormat: moment(date).format('YYYY-MM-DD'),
                          });
                          formProps.setFieldValue('endDate', {
                            date: null,
                            queryFormat: '',
                          });
                        }}
                        setEndDate={(date) => {
                          if (comparisonReportError.message === 'You must specify start and end dates when selecting a custom date range' && formProps.values.startDate.date !== null && formProps.values.endDate.date === null) {
                            dispatch(resetComparisonReportError());
                          }
                          formProps.setFieldValue('endDate', {
                            date,
                            queryFormat: moment(date).format('YYYY-MM-DD'),
                          });
                        }}
                        startDate={formProps.values.startDate.date}
                        endDate={formProps.values.endDate.date}
                        reportType='multiDay'
                      />
                    ) : null}
                  </FormSection>

                  <OptionsSection>
                    <OptionsColumn>
                      <FormSection>
                        <FormSectionHeader>
                          Select Preferred Date Format
                        </FormSectionHeader>
                        <RadioGroup
                          className='radioOptions'
                          columns
                          aria-labelledby='preferred-date-format-label'
                          value={formProps.values.dateFormat}
                          onChange={(e) => {
                            if (comparisonReportError.message === 'You must select a preferred date format') {
                              dispatch(resetComparisonReportError());
                            }
                            formProps.setFieldValue(
                              'dateFormat',
                              e.target.value,
                            );
                          }}
                          name='radio-buttons-group'
                        >
                          <FormControlLabel
                            value='MM/DD/YY'
                            label='MM/DD/YY'
                            control={<Radio color='default' />}
                          />
                          <FormControlLabel
                            value='YYYY-MM-DD'
                            label='YYYY-MM-DD'
                            control={<Radio color='default' />}
                          />
                        </RadioGroup>
                      </FormSection>
                    </OptionsColumn>
                    <OptionsColumn>
                      <FormSection>
                        <FormSectionHeader>Report Options</FormSectionHeader>
                        <ReportOptionsContainer>
                          <ReportOptionsRow>
                            <Toggle
                              height='80%'
                              checked={formProps.values.includeManualMaxes}
                              icons={false}
                              onChange={() => {
                                if (comparisonReportError.message === 'Please include at least one type of max record from report options to run this report') {
                                  dispatch(resetComparisonReportError());
                                }
                                formProps.setFieldValue(
                                  'includeManualMaxes',
                                  !formProps.values.includeManualMaxes,
                                );
                              }}
                              value={formProps.values.includeManualMaxes}
                            />
                            <ToggleLabel fontSize='16px' fontWeight={400}>
                              Include Manually Entered Maxes
                            </ToggleLabel>
                          </ReportOptionsRow>
                          <ReportOptionsRow>
                            <Toggle
                              height='80%'
                              checked={formProps.values.includeGeneratedMaxes}
                              icons={false}
                              onChange={() => {
                                if (comparisonReportError.message === 'Please include at least one type of max record from report options to run this report') {
                                  dispatch(resetComparisonReportError());
                                }
                                formProps.setFieldValue(
                                  'includeGeneratedMaxes',
                                  !formProps.values.includeGeneratedMaxes,
                                );
                              }}
                              value={formProps.values.includeGeneratedMaxes}
                            />
                            <ToggleLabel>
                              Include Workout Generated Maxes
                            </ToggleLabel>
                          </ReportOptionsRow>
                          <ReportOptionsRow>
                            <Toggle
                              height='80%'
                              checked={formProps.values.includeEmptyExercises}
                              icons={false}
                              onChange={() => {
                                formProps.setFieldValue(
                                  'includeEmptyExercises',
                                  !formProps.values.includeEmptyExercises,
                                );
                              }}
                              value={formProps.values.includeEmptyExercises}
                            />
                            <ToggleLabel>Include Empty Exercises</ToggleLabel>
                          </ReportOptionsRow>
                        </ReportOptionsContainer>
                      </FormSection>
                    </OptionsColumn>
                  </OptionsSection>
                </>
                <>
                  <FormGroup>
                    <FormSectionHeader style={{ marginBottom: '0' }}>
                      Select Exercises
                    </FormSectionHeader>
                    <FieldArray
                      name='exerciseIds'
                      render={(arrayHelpers) => (
                        <>
                          <ExerciseSelectColumn>
                            {formProps.values.exerciseIds.length > 0
                              && formProps.values.exerciseIds.map(
                                (exercise, index) => (
                                  <ExerciseSelectContainer>
                                    <FormLabel>
                                      Exercise #
                                      {index + 1}
                                    </FormLabel>
                                    {index !== 0 && (
                                      <Link
                                        className='remove-link'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          arrayHelpers.remove(index);
                                        }}
                                        type='button'
                                      >
                                        &#10005;
                                      </Link>
                                    )}
                                    <Select
                                      classNamePrefix='react-select'
                                      defaultValue={
                                        formProps.values.exerciseIds[index]
                                      }
                                      options={exercises.filter(
                                        (exerciseObj) => !formProps.values.exerciseIds.includes(
                                          exerciseObj,
                                        ),
                                      )}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      name='exercise'
                                      onChange={(option) => {
                                        if (comparisonReportError.message === 'You must select at least one exercise') {
                                          dispatch(resetComparisonReportError());
                                        }
                                        formProps.setFieldValue(
                                          `exerciseIds.${index}`,
                                          option,
                                        );
                                      }}
                                      isOptionSelected={(option) => formProps.values.exerciseIds
                                        === option.id}
                                      value={
                                        formProps.values.exerciseIds[index]
                                      }
                                    />
                                  </ExerciseSelectContainer>
                                ),
                              )}
                          </ExerciseSelectColumn>
                          {formProps.values.exerciseIds.length <= 9 && (
                            <AddExerciseButtonContainer>
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  arrayHelpers.push('');
                                }}
                                cta='Add Exercise'
                                icon='plus'
                              />
                            </AddExerciseButtonContainer>
                          )}
                        </>
                      )}
                    />
                  </FormGroup>
                </>
              </>

            </animated.div>
            {isLoadingComparisonReport && (
              <animated.div style={spinnerAnimation}>
                <SpinnerContainer>
                  <Spinner />
                </SpinnerContainer>
              </animated.div>
            )}

            <Button
              cta='Run Report'
              className='modal-button'
              type='submit'
              customColor={theme.colors.green}
              fullWidth
              noBorder
              large
              square
              primary
              bottom
            />
          </Form>
        )}
      </FormHandler>
    </>
  );
};

export default ComparisonReportForm;
